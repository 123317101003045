<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Page Header</div>
                </div>
            </div>
        </div>

        <div
            class="c-page-header s-swiper js-page-header"
            :data-component-id="componentId"
            :class="{ 'has-switcher': componentData.settings.show_type_switcher }">
            <swiper-container
                v-if="componentData.slides.length > 1"
                ref="containerRef"
                :a11y-enabled="true"
                :auto-height="true"
                :modules="[SwiperA11y, SwiperNavigation]"
                :navigation-enabled="true"
                :navigation-next-el="`.js-page-header[data-component-id=${componentId}] .js-swiper-button-next`"
                :navigation-prev-el="`.js-page-header[data-component-id=${componentId}] .js-swiper-button-prev`"
                :slides-per-view="1"
                :space-between="0">
                <swiper-slide v-for="(slide, index) in componentData.slides" :key="index">
                    <div class="c-page-header__slide">
                        <div v-if="slide.vimeo_video_id" class="c-page-header__slide-media is-video">
                            <ScriptVimeoPlayer
                                :id="slide.vimeo_video_id"
                                ref="video"
                                trigger="visible"
                                above-the-fold
                                :vimeoOptions="vimeoOptions">
                                <template #placeholder>
                                    <div
                                        v-if="slide?.image?.attachments?.['1920x1080']"
                                        class="c-page-header__video-placeholder"
                                        :class="[{ 'is-desktop': slide.mobile_image }]"
                                        v-html="slide.image.attachments['1920x1080']"></div>

                                    <div
                                        v-if="slide.mobile_image && slide?.mobile_image?.attachments?.['630x680']"
                                        class="c-page-header__video-placeholderis-mobile"
                                        v-html="slide.mobile_image.attachments['630x680']"></div>
                                </template>

                                <template #loading>
                                    <div
                                        v-if="slide?.image?.attachments?.['1920x1080']"
                                        class="c-page-header__video-placeholder"
                                        :class="[{ 'is-desktop': slide.mobile_image }]"
                                        v-html="slide.image.attachments['1920x1080']"></div>

                                    <div
                                        v-if="slide.mobile_image && slide?.mobile_image?.attachments?.['630x680']"
                                        class="c-page-header__video-placeholderis-mobile"
                                        v-html="slide.mobile_image.attachments['630x680']"></div>
                                </template>
                            </ScriptVimeoPlayer>
                        </div>

                        <template v-else>
                            <div
                                class="c-page-header__slide-media is-image"
                                :class="[{ 'is-desktop': slide.mobile_image }]"
                                v-if="slide?.image?.attachments?.['1920x1080']"
                                v-html="slide.image.attachments['1920x1080']"></div>

                            <div
                                class="c-page-header__slide-media is-image is-mobile"
                                v-if="slide.mobile_image && slide?.mobile_image?.attachments?.['630x680']"
                                v-html="slide.mobile_image.attachments['630x680']"></div>
                        </template>

                        <div
                            v-if="slide.settings.overlay_transparency > 0"
                            class="c-page-header__slide-overlay"
                            :style="`background-color: rgba(0, 0, 0, ${slide.settings.overlay_transparency / 100})`"></div>

                        <div class="c-page-header__slide-overlay is-mobile"></div>

                        <div class="c-page-header__slide-content-wrapper">
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x align-middle">
                                    <div
                                        class="small-12 cell"
                                        :class="
                                            slide?.settings?.extend_content_width ? 'medium-12 large-8' : 'medium-6'
                                        ">
                                        <template v-if="slide.title">
                                            <h1
                                                v-if="slide.settings.heading_level == 'h1'"
                                                class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                                :class="[{ 'has-reduced-size': slide?.settings?.reduce_title_size }]"
                                                v-html="slide.title"></h1>

                                            <h2
                                                v-else-if="slide.settings.heading_level == 'h2'"
                                                class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                                :class="[{ 'has-reduced-size': slide?.settings?.reduce_title_size }]"
                                                v-html="slide.title"></h2>

                                            <h3
                                                v-else-if="slide.settings.heading_level == 'h3'"
                                                class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                                :class="[{ 'has-reduced-size': slide?.settings?.reduce_title_size }]"
                                                v-html="slide.title"></h3>

                                            <div
                                                v-else
                                                class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                                :class="[{ 'has-reduced-size': slide?.settings?.reduce_title_size }]"
                                                v-html="slide.title"></div>
                                        </template>

                                        <div
                                            v-if="slide.content"
                                            class="c-page-header__slide-content s-wp-cms wow animate__animated animate__fadeInUp"
                                            v-html="slide.content"></div>

                                        <NuxtLink
                                            v-if="slide.link"
                                            class="c-page-header__slide-link wow animate__animated animate__fadeInUp"
                                            :to="slide.link.url">
                                            <span v-html="slide.link.title"></span>
                                        </NuxtLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>

            <div v-else class="c-page-header__slide">
                <div v-if="componentData.slides[0].vimeo_video_id" class="c-page-header__slide-media is-video">
                    <ScriptVimeoPlayer
                        :id="componentData.slides[0].vimeo_video_id"
                        ref="video"
                        trigger="visible"
                        above-the-fold
                        :vimeoOptions="vimeoOptions">
                        <template #placeholder>
                            <div
                                v-if="componentData.slides[0]?.image?.attachments?.['1920x1080']"
                                class="c-page-header__video-placeholder"
                                :class="[{ 'is-desktop': componentData.slides[0].mobile_image }]"
                                v-html="componentData.slides[0].image.attachments['1920x1080']"></div>

                            <div
                                v-if="
                                    componentData.slides[0].mobile_image &&
                                    componentData.slides[0]?.mobile_image?.attachments?.['630x680']
                                "
                                class="c-page-header__video-placeholder is-mobile"
                                v-html="componentData.slides[0].mobile_image.attachments['630x680']"></div>
                        </template>

                        <template #loading>
                            <div
                                v-if="componentData.slides[0]?.image?.attachments?.['1920x1080']"
                                class="c-page-header__video-placeholder"
                                :class="[{ 'is-desktop': componentData.slides[0].mobile_image }]"
                                v-html="componentData.slides[0].image.attachments['1920x1080']"></div>

                            <div
                                v-if="
                                    componentData.slides[0].mobile_image &&
                                    componentData.slides[0]?.mobile_image?.attachments?.['630x680']
                                "
                                class="c-page-header__video-placeholder is-mobile"
                                v-html="componentData.slides[0].mobile_image.attachments['630x680']"></div>
                        </template>
                    </ScriptVimeoPlayer>
                </div>

                <template v-else>
                    <div
                        class="c-page-header__slide-media is-image"
                        :class="[{ 'is-desktop': componentData.slides[0].mobile_image }]"
                        v-html="componentData.slides[0].image.attachments['1920x1080']"></div>

                    <div
                        v-if="
                            componentData.slides[0].mobile_image &&
                            componentData.slides[0]?.mobile_image?.attachments?.['630x680']
                        "
                        class="c-page-header__slide-media is-image is-mobile"
                        v-html="componentData.slides[0].mobile_image.attachments['630x680']"></div>
                </template>

                <div
                    v-if="componentData.slides[0].settings.overlay_transparency > 0"
                    class="c-page-header__slide-overlay"
                    :style="`background-color: rgba(0, 0, 0, ${componentData.slides[0].settings.overlay_transparency / 100})`"></div>

                <div class="c-page-header__slide-overlay is-mobile"></div>

                <div class="c-page-header__slide-content-wrapper">
                    <div class="grid-container">
                        <div class="grid-x grid-margin-x align-middle">
                            <div
                                class="small-12 cell"
                                :class="
                                    componentData.slides[0].settings.extend_content_width
                                        ? 'medium-12 large-8'
                                        : 'medium-6'
                                ">
                                <template v-if="componentData.slides[0].title">
                                    <h1
                                        v-if="componentData.slides[0].settings.heading_level == 'h1'"
                                        class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                        :class="[
                                            { 'has-reduced-size': componentData.slides[0].settings.reduce_title_size },
                                        ]"
                                        v-html="componentData.slides[0].title"></h1>

                                    <h2
                                        v-else-if="componentData.slides[0].settings.heading_level == 'h2'"
                                        class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                        :class="[
                                            { 'has-reduced-size': componentData.slides[0].settings.reduce_title_size },
                                        ]"
                                        v-html="componentData.slides[0].title"></h2>

                                    <h3
                                        v-else-if="componentData.slides[0].settings.heading_level == 'h3'"
                                        class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                        :class="[
                                            { 'has-reduced-size': componentData.slides[0].settings.reduce_title_size },
                                        ]"
                                        v-html="componentData.slides[0].title"></h3>

                                    <div
                                        v-else
                                        class="c-page-header__slide-title wow animate__animated animate__fadeInUp"
                                        :class="[{ 'has-reduced-size': slide?.settings?.reduce_title_size }]"
                                        v-html="componentData.slides[0].title"></div>
                                </template>

                                <div
                                    v-if="componentData.slides[0].content"
                                    class="c-page-header__slide-content s-wp-cms wow animate__animated animate__fadeInUp"
                                    v-html="componentData.slides[0].content"></div>

                                <NuxtLink
                                    v-if="componentData.slides[0].link"
                                    class="c-page-header__slide-link wow animate__animated animate__fadeInUp"
                                    :to="componentData.slides[0].link.url">
                                    <span v-html="componentData.slides[0].link.title"></span>
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-page-header__toggle-buttons" v-if="showSwitcher">
                <div class="grid-container">
                    <div class="grid-x align-center">
                        <div class="cell small-11 medium-7 large-5">
                            <GlobalToggleButtons />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-page-header__breadcrumbs">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <PartialsBreadcrumbs
                            :page_title="
                                componentData.slides ? componentData.slides[0].title : componentData.slides[0].title
                            " />
                    </div>
                </div>
            </div>
        </div>

        <div class="c-page-header__search-bar" v-if="showSearchBar || showSwitcher">
            <div id="search" class="grid-container">
                <div class="grid-x align-center">
                    <div class="cell small-12 large-8 medium-10" v-if="searchBarType === 'ownership'">
                        <OwnershipSlimSearchbar />
                    </div>

                    <div class="cell small-12" v-if="searchBarType === 'holiday'">
                        <SearchBar />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const siteStore = useSiteStore();
    const componentSettings = props?.componentData?.settings;
    const showSwitcher = computed(() => !!componentSettings?.show_type_switcher);
    const componentId = useId();
    const video = ref();

    const vimeoOptions = ref({
        background: true,
    });

    const showSearchBar = computed(() => {
        return !componentSettings?.show_type_switcher && componentSettings?.show_search_bar;
    });

    const searchBarType = computed(() => {
        if (showSwitcher.value) {
            // Use stored value if available, otherwise fall back to default
            return siteStore.state.holidayFilterState || componentSettings?.default_switcher_value || "ownership";
        } else if (showSearchBar.value) {
            // Use the CMS-defined bar type when switcher is off
            return componentSettings?.search_bar_type || "ownership";
        }

        return "ownership";
    });

    if (showSwitcher.value && !siteStore.state.holidayFilterState) {
        siteStore.state.holidayFilterState = componentSettings?.default_switcher_value || "ownership";
    }

    if (!showSwitcher.value && !showSearchBar.value) {
        siteStore.state.holidayFilterState = null;
    }
</script>
